import React from "react";
import { useTranslation } from "react-i18next";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import Corrosion from "./ResultSections/Corrosion";
import Saturation from "./ResultSections/Saturation";
import Criticality from "./ResultSections/Criticality";
import CorrosionOptimization from "./ResultSections/CorrosionOptimization";
import IncrustationOptimization from "./ResultSections/IncrustationOptimization";
import PungoButton from "pungo-ui/PungoButton";
import { Download } from "@mui/icons-material";
import { useAppDispatch } from "app/hooks";
import {
  downloadResultsReport,
} from "store/actions/planActions";
import { useSelector } from "react-redux";
import { selectReportReference } from "store/slices/plan";

import styles from "./index.module.scss";

const ResultsProSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reportReference = useSelector(selectReportReference);

  return (
    <PungoSectionCard title={t("DemoPage.ResultsSection.results")}>
      <div className={styles.container}>
        <div className={styles.resultsContainer}>
          <Corrosion />
          <Saturation />
          <Criticality />
          <CorrosionOptimization />
          <IncrustationOptimization />
        </div>
        <PungoButton
          label={`${t("proPlan.downloadResults")}`}
          type="text"
          onClick={() => dispatch(downloadResultsReport(reportReference))}
          color="secondary"
          icon={<Download />}
          disabled={reportReference === -1}
        />
      </div>
    </PungoSectionCard>
  );
};

export default ResultsProSection;
