import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";

import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import { downloadUsageReportById } from "store/actions/userCompanyActions";
import {IUsage, selectTotalUsagePages, selectUsages} from "store/slices/company";
import { fetchFilteredUsagesForCompanyAdmin } from "store/actions/companyActions";

const UsageTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usageData = useSelector(selectUsages);
  const totalUsagePages = useSelector(selectTotalUsagePages);

  const initialIdFilter = "";
  const initialUserIdFilter = "";
  const initialEmailFilter = "";
  const initialFromDateFilter = "";
  const initialToDateFilter = "";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [userIdFilter, setUserIdFilter] = useState(initialUserIdFilter);
  const [emailFilter, setEmailFilter] = useState(initialEmailFilter);
  const [fromDateFilter, setFromDateFilter] = useState(
    initialFromDateFilter
  );
  const [toDateFilter, setToDateFilter] = useState(
    initialToDateFilter
  );

  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [filteredUsageCallTimeoutId, setFilteredUsageTimeoutId] = useState(0);

  const paramsUsagePerCompanyTable = {
    ...(idFilter && { id: idFilter }),
    ...(userIdFilter && { userId: userIdFilter }),
    ...(emailFilter && { userEmail: emailFilter }),
    ...(fromDateFilter && { fromTime: fromDateFilter }),
    ...(toDateFilter && { toTime: toDateFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  useEffect(() => {
    if(!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterUsage(0);
        allFiltersChange.current = false;
      } else {
        if (filteredUsageCallTimeoutId) {
          clearTimeout(filteredUsageCallTimeoutId);
        }
        setFilteredUsageTimeoutId(setTimeout(handleFilterUsage, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    userIdFilter,
    fromDateFilter,
    toDateFilter,
    emailFilter
  ]);

  useEffect(() => {
    handleFilterUsage(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },     [
    pageSize,
    currentTablePage,
    sortBy,
    sortType
  ]);

  useEffect(() => {
    if (currentTablePage >= totalUsagePages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalUsagePages]);

  function handleFilterUsage(_data: Number): void {
    dispatch(fetchFilteredUsagesForCompanyAdmin(paramsUsagePerCompanyTable));
  }

  const columnsForUsagePerCompanyTable: ITableColumn[] = [
    {
      id: "time",
      field: "time",
      headerName: "time",
      minWidth: 500,
      sortable: false,
      filter: {
        name: t("admin.filtering.date"),
        type: "date",
        value: fromDateFilter,
        auxValue:toDateFilter,
        setter: setFromDateFilter,
        auxSetter:setToDateFilter,
        eraser: () => {allFiltersChange.current=true; setFromDateFilter("");},
        auxEraser: () => {allFiltersChange.current=true; setToDateFilter("");}
      },
    },
    {
      id: "user",
      field: "user",
      headerName: "user",
      valueGetter: (params) => {
        return `${params?.row?.user?.name} (${params?.row?.user?.email})` || "";
      },
      minWidth: 400,
      sortable: false,
      filter: {
        name: t("admin.filtering.user"),
        type: "text",
        value: emailFilter,
        setter: setEmailFilter,
        eraser: () => {allFiltersChange.current=true; setEmailFilter("");}
      },
    },
  ];

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setUserIdFilter(initialUserIdFilter);
    setEmailFilter(initialEmailFilter);
    setFromDateFilter(initialFromDateFilter);
    setToDateFilter(initialToDateFilter);
    allFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    userIdFilter !== initialUserIdFilter ||
    emailFilter !== initialEmailFilter ||
    fromDateFilter !== initialFromDateFilter||
    toDateFilter !== initialToDateFilter;

  const onDownloadUsageById = (row: IUsage) => {
    if (row) {
      dispatch(downloadUsageReportById(row.id));
    }
  };

  return (
    <div>
      <AdminTable
        columns={columnsForUsagePerCompanyTable}
        data={usageData || []}
        handleOnDownload={onDownloadUsageById}
        actions={["download"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalUsagePages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
    </div>
  );
};

export default UsageTable;
