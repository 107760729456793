import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Download, UploadFile } from "@mui/icons-material";
import { useAppDispatch } from "app/hooks";

import {
  clearProPlanErrors,
  selectProPlanErrors,
  selectSelectedPlan,
} from "store/slices/plan";
import {
  downloadCalculationTemplate,
  uploadCalculationTemplate,
  uploadCalculationTemplateWithModel,
} from "store/actions/planActions";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import { selectUserRole } from "store/slices/auth";
import PungoModal from "pungo-ui/PungoModal";
import PungoButton from "pungo-ui/PungoButton";
import PlanModalContainer from "components/PlanModalContainer";
import ProBadge from "components/common/ProBadge";
import PungoInput from "pungo-ui/PungoInput";
import { fetchAvailableModelList } from "store/actions/modelActions";
import { selectModelList } from "store/slices/model";

import styles from "./index.module.scss";

const InstructionsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedPlan = useSelector(selectSelectedPlan);
  const modelList = useSelector(selectModelList);
  const userRoles = useSelector(selectUserRole);
  const errors = useSelector(selectProPlanErrors);
  console.log("🚀 ~ errors:", errors);

  const isFreeUser = userRoles?.includes("free_plan");
  const isCompanyUser =
    userRoles.includes("company_admin") || userRoles.includes("company_user");

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectedCalculationModelId, setSelectedCalculationModelId] =
    useState(0);
  const [showNotAllowedFileModal, setShowNotAllowedFileModal] = useState(false);

  const getInstructionsContent = () => (
    <div className={styles.text}>
      <Trans>{t("DemoPage.InstructionsSection.steps")}</Trans>
      <div className={styles.note}>
        <Trans>{t("DemoPage.InstructionsSection.note")}</Trans>
      </div>
    </div>
  );

  const clearFileInput = (ctrl: any) => {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  };

  const handleOnCloseModal = () => {
    setShowNotAllowedFileModal(false);
    clearFileInput(document.getElementById("file"));
    setUploadedFile(null);
  };

  const handleOnCloseErrorsModal = () => {
    dispatch(clearProPlanErrors());
  };

  useEffect(() => {
    setSelectedCalculationModelId(modelList[0]?.id || 0);
  }, [modelList]);

  useEffect(() => {
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(
        uploadedFile.name.lastIndexOf(".") + 1
      );
      if (extension !== "xlsx") {
        setShowNotAllowedFileModal(true);
      }
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (isCompanyUser) {
      dispatch(fetchAvailableModelList());
    }
  }, []);

  const onUploadFile = () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("filename", uploadedFile.name);

      if (isCompanyUser && selectedCalculationModelId !== 0) {
        dispatch(
          uploadCalculationTemplateWithModel(formData, {
            wellModelId: selectedCalculationModelId,
          })
        );
      } else {
        dispatch(uploadCalculationTemplate(formData));
      }

      setUploadedFile(null);
      clearFileInput(document.getElementById("file"));
    }
  };

  const getProInstructionsContent = () => (
    <div className={styles.text}>
      <Trans>
        {isCompanyUser
          ? t("DemoPage.InstructionsSection.proStepsForCompanyUsers")
          : t("DemoPage.InstructionsSection.proSteps")}
      </Trans>
      {isFreeUser && (
        <div className={styles.note}>
          <Trans>{t("DemoPage.InstructionsSection.proResultsNote")}</Trans>
        </div>
      )}
      {isCompanyUser && (
        <div className={styles.modelInput}>
          <PungoInput
            name={`${t("DemoPage.InstructionsSection.modelSelector")}`}
            value={selectedCalculationModelId}
            onChange={setSelectedCalculationModelId}
            type="select"
            options={modelList?.map(({ id, name }) => {
              return { value: id, label: name };
            })}
          />
        </div>
      )}
      <div className={styles.buttonSection}>
        <ProBadge />
        <PungoButton
          label={`${t("manageProfile.downloadFileButton")}`}
          type="text"
          onClick={() => dispatch(downloadCalculationTemplate())}
          color="secondary"
          icon={<Download />}
          disabled={isFreeUser}
        />
        <input
          type="file"
          id="file"
          onChange={(event: any) => setUploadedFile(event.target.files[0])}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={styles.fileInput}
          disabled={isFreeUser}
        />
        <PungoButton
          label={`${t("manageProfile.uploadFileButton")}`}
          type="text"
          onClick={onUploadFile}
          color="secondary"
          icon={<UploadFile />}
          disabled={isFreeUser || !uploadedFile}
        />
      </div>
    </div>
  );

  return (
    <PungoSectionCard title={t("DemoPage.InstructionsSection.instructions")}>
      <div className={styles.container}>
        {selectedPlan === "free"
          ? getInstructionsContent()
          : getProInstructionsContent()}
      </div>
      <PungoModal
        open={showNotAllowedFileModal}
        classNames={styles.modal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseModal,
        }}
      >
        <div className={styles.modalChild}>
          {t("manageProfile.fileNotAllowed")}
        </div>
      </PungoModal>
      <PungoModal
        open={!!errors}
        classNames={styles.modal}
        title={`${t("admin.problemLabel")}`}
        handleClose={handleOnCloseErrorsModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseErrorsModal,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(errors, null, 4)}</pre>
        </code>
      </PungoModal>
      <PlanModalContainer />
    </PungoSectionCard>
  );
};

export default InstructionsSection;
