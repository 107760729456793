import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";

import {
  clearUsageDataByUser,
  clearUsageDetailById,
  selectAllRoles,
  selectAllUsers,
  selectTotalUsersPages,
  selectUsageById,
  selectUsageByUserId,
  selectUsageTotalUsersPages,
} from "store/slices/user";
import AdminTable, { ITableColumn } from "../../../common/AdminTable";
import {
  createUser,
  deleteUsers,
  editUser,
  fetchUsageById,
  fetchUsageByUserId,
  getFilteredUsers,
  getRoles,
  IPaymentTypes,
  IUsageTypes,
} from "store/actions/userActions";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import { isEmail } from "components/utils/validations";
import { selectCompaniesForSelect } from "store/slices/company";

import styles from "./index.module.scss";
import { fetchAvailableCompanyList } from "store/actions/companyActions";

const AdminUsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usersData = useSelector(selectAllUsers);
  const rolesList = useSelector(selectAllRoles);
  const usageByUserData = useSelector(selectUsageByUserId);
  const detailedData = useSelector(selectUsageById);
  const totalUserPages = useSelector(selectTotalUsersPages);
  const totalUsageUserPages = useSelector(selectUsageTotalUsersPages);
  const companiesList = useSelector(selectCompaniesForSelect);

  const selectableCompanyList = companiesList
    .map(({ id, name }) => {
      return { value: id, label: name };
    })
    .concat({
      label: `${t("user.noCompanySelected")}`,
      value: 0,
    });

  const initialIdFilter = "";
  const initialStatusFilter = "ACTIVE";
  const initialRoleFilter = "all";
  const initialNameFilter = "";
  const initialMailFilter = "";
  const initialCompanyFilter = "";
  const initialUnverifiedCompanyFilter = "";

  const initialIdFilterByUserId = "";
  const initialDateFilterByUserId = "";
  const initialUserIdFilterByUserId = "";
  const initialMailFilterByUserId = "";
  const initialCallTypeFilterByUserId = "all";

  const firstUpdate = useRef(true);
  const allFiltersChange = useRef(true);
  const allUsageFiltersChange = useRef(true);

  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [roleFilter, setRoleFilter] = useState(initialRoleFilter);
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [mailFilter, setMailFilter] = useState(initialMailFilter);
  const [companyFilter, setCompanyFilter] = useState(initialCompanyFilter);
  const [unverifiedCompanyFilter, setUnverifiedCompanyFilter] = useState(
    initialUnverifiedCompanyFilter
  );

  const [usageDateFilter, setUsageDateFilter] = useState(
    initialDateFilterByUserId
  );
  const [usageIdFilter, setUsageIdFilter] = useState(initialIdFilterByUserId);
  const [usageUserIdFilter, setUsageUserIdFilter] = useState(
    initialUserIdFilterByUserId
  );
  const [usageMailFilter, setUsageMailFilter] = useState(
    initialMailFilterByUserId
  );
  const [usageCallTypeFilter, setUsageCallTypeFilter] = useState(
    initialCallTypeFilterByUserId
  );

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [name, setName] = useState("");
  const [unverifiedCompany, setUnverifiedCompany] = useState("");
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState<number>(0);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [usageType, setUsageType] = useState<IUsageTypes>("");
  const [paymentType, setPaymentType] = useState<IPaymentTypes>("");
  const [remainingUsages, setRemainingUsages] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [passwordChangeCode, setPasswordChangeCode] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [usagePageSize, setUsagePageSize] = useState(10);
  const [currentUsageTablePage, setCurrentUsageTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [usageSortBy, setUsageSortBy] = useState("id");
  const [usageSortType, setUsageSortType] = useState("asc");

  const [showMainModal, setShowMainModal] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showDetailedUsageModal, setShowDetailedUsageModal] = useState(false);
  const [filteredUsersCallTimeoutId, setFilteredUsersTimeoutId] = useState(0);
  const [filteredUsersByIdCallTimeoutId, setFilteredUsersByIdTimeoutId] =
    useState(0);

  const isAdminUser = role === "admin";
  const isProUser = role === "pro_plan";
  const isFreeUser = role === "free_plan";
  const isCompanyUser =
    role === "company_admin" || role === "company_user";

  const isNumberUsage = usageType === "NUMBER_OF_USAGES";
  const isEndDateUsage = usageType === "END_TIME";
  const hasUserAssignedACompany = companyId !== 0;

  const filterStatusOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.active"), value: "ACTIVE" },
    { label: t("admin.filtering.removed"), value: "REMOVED" },
  ];

  const filterCallTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.proPlan"), value: "PRO_PLAN" },
    { label: t("admin.filtering.simplePlan"), value: "SIMPLE_PLAN" },
  ];

  const paramsForUserTable = {
    ...(idFilter && { id: idFilter }),
    ...(statusFilter !== "all" && { status: statusFilter }),
    ...(nameFilter && { name: nameFilter }),
    ...(companyFilter && { company: companyFilter }),
    ...(unverifiedCompanyFilter && {
      unverifiedCompanyName: unverifiedCompanyFilter,
    }),
    ...(mailFilter && { email: mailFilter }),
    ...(roleFilter !== "all" && { role: roleFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  const paramsForUsageTable = {
    ...(usageDateFilter && { time: usageDateFilter }),
    ...(usageIdFilter && { id: usageIdFilter }),
    ...(usageUserIdFilter && { user_id: usageUserIdFilter }),
    ...(usageMailFilter && { user_email: usageMailFilter }),
    ...(usageCallTypeFilter !== "all" && { callType: usageCallTypeFilter }),
    ...{ pageSize: usagePageSize },
    ...{ pageNumber: currentUsageTablePage },
    ...{ sortType: usageSortType },
    ...{ sortBy: usageSortBy },
  };

  /**
   * User filter effects
   */
  useEffect(() => {
    if (!firstUpdate.current) {
      if (allFiltersChange.current) {
        handleFilterUsers(0);
        allFiltersChange.current = false;
      } else {
        if (filteredUsersCallTimeoutId) {
          clearTimeout(filteredUsersCallTimeoutId);
        }
        setFilteredUsersTimeoutId(setTimeout(handleFilterUsers, 800));
      }
    } else {
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idFilter,
    nameFilter,
    mailFilter,
    companyFilter,
    unverifiedCompanyFilter,
  ]);

  useEffect(() => {
    handleFilterUsers(0);
    allFiltersChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, roleFilter, pageSize, currentTablePage, sortBy, sortType]);

  useEffect(() => {
    if (currentTablePage >= totalUserPages) {
      setCurrentTablePage(0);
    }
  }, [currentTablePage, totalUserPages]);

  /**
   * USages per user filter effects
   */
  useEffect(() => {
    if (allUsageFiltersChange.current) {
      handleFilterUsersById(0);
      allUsageFiltersChange.current = false;
    } else {
      if (filteredUsersByIdCallTimeoutId) {
        clearTimeout(filteredUsersByIdCallTimeoutId);
      }
      setFilteredUsersByIdTimeoutId(setTimeout(handleFilterUsersById, 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageDateFilter, usageIdFilter, usageUserIdFilter, usageMailFilter]);

  useEffect(() => {
    handleFilterUsersById(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usageCallTypeFilter,
    usagePageSize,
    currentUsageTablePage,
    usageSortType,
    usageSortBy,
  ]);

  useEffect(() => {
    if (currentUsageTablePage >= totalUsageUserPages) {
      setCurrentUsageTablePage(0);
    }
  }, [currentUsageTablePage, totalUsageUserPages]);

  function handleFilterUsers(_data: Number): void {
    dispatch(getFilteredUsers(paramsForUserTable));
  }

  function handleFilterUsersById(_data: Number): void {
    if (selectedUserId) {
      dispatch(fetchUsageByUserId(selectedUserId, paramsForUsageTable));
    }
  }

  const columnsForUserTable: ITableColumn[] = [
    {
      id: "id",
      field: "id",
      headerName: "ID",
      minWidth: 180,
      sortable: false,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setIdFilter("");
        },
      },
    },
    {
      id: "status",
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.status"),
        type: "list",
        value: statusFilter,
        setter: setStatusFilter,
        options: filterStatusOptions,
      },
    },
    {
      id: "role",
      field: "role",
      headerName: "Role",
      minWidth: 230,
      sortable: false,
      filter: {
        name: t("admin.filtering.role"),
        type: "list",
        value: roleFilter,
        setter: setRoleFilter,
        options: rolesList
          .map(({ name }) => {
            return { label: name, value: name };
          })
          .concat({
            value: "all",
            label: t("admin.filtering.all"),
          }),
      },
    },
    {
      id: "name",
      field: "name",
      headerName: "Name",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.name"),
        type: "text",
        value: nameFilter,
        setter: setNameFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setNameFilter("");
        },
      },
    },
    {
      id: "email",
      field: "email",
      headerName: "Email",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: mailFilter,
        setter: setMailFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setMailFilter("");
        },
      },
    },
    {
      id: "company",
      field: "company",
      headerName: "Company",
      valueGetter: (params) => params?.row?.company?.name || "",
      minWidth: 200,
      sortable: false,
      filter: {
        name: t("admin.filtering.company"),
        type: "text",
        value: companyFilter,
        setter: setCompanyFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setCompanyFilter("");
        },
      },
    },
    {
      id: "unverifiedCompanyName",
      field: "unverifiedCompanyName",
      headerName: "unverifiedCompanyName",
      minWidth: 300,
      sortable: false,
      filter: {
        name: t("admin.filtering.unverifiedCompany"),
        type: "text",
        value: unverifiedCompanyFilter,
        setter: setUnverifiedCompanyFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setUnverifiedCompanyFilter("");
        },
      },
    },
  ];

  const columnsForUsageTable: ITableColumn[] = [
    {
      id: "time",
      field: "time",
      headerName: "Time",
      sortable: false,
      minWidth: 250,
      filter: {
        name: t("admin.filtering.date"),
        type: "text",
        value: usageDateFilter,
        setter: setUsageDateFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setUsageDateFilter("");
        },
      },
    },
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 180,
      filter: {
        name: "ID",
        type: "text",
        value: usageIdFilter,
        setter: setUsageIdFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setUsageIdFilter("");
        },
      },
    },
    {
      id: "userId",
      field: "userId",
      headerName: "User Id",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.userId"),
        type: "text",
        value: usageUserIdFilter,
        setter: setUsageUserIdFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setUsageUserIdFilter("");
        },
      },
    },
    {
      id: "userName",
      field: "userName",
      headerName: "User's email",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: usageMailFilter,
        setter: setUsageMailFilter,
        eraser: () => {
          allFiltersChange.current = true;
          setUsageMailFilter("");
        },
      },
    },
    {
      id: "callType",
      field: "callType",
      headerName: "Call type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.callType"),
        type: "list",
        value: usageCallTypeFilter,
        setter: setUsageCallTypeFilter,
        options: filterCallTypeOptions,
      },
    },
  ];

  const userToEdit = usersData?.find(({ id }) => id === selectedUserId);

  useEffect(() => {
    setName(userToEdit?.name || "");
    setUnverifiedCompany(userToEdit?.unverifiedCompanyName || "");
    setCompanyId(userToEdit?.company?.id || 0);
    setEmail(userToEdit?.email || "");
    setRole(userToEdit?.role || "");
    setEmailVerificationCode(userToEdit?.emailVerificationCode || "");
    setPasswordChangeCode(userToEdit?.passwordChangeCode || "");
    setIsEmailVerified(userToEdit?.emailVerified || false);
    setIsSurveyCompleted(userToEdit?.surveyCompleted || false);
    setUsageType(userToEdit?.planDetails?.planUsageType || "");
    setPaymentType(userToEdit?.planDetails?.planPaymentType || "");
    setRemainingUsages(userToEdit?.planDetails?.planRemainingUsages || 0);
    setEndDate(userToEdit?.planDetails?.planEndDate || "");
  }, [userToEdit]);

  userToEdit?.planDetails?.planEndDate
    ? new Date(userToEdit?.planDetails?.planEndDate)
    : new Date();

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUsageByUserId(selectedUserId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsageModal]);

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetailedUsageModal]);

  const handleOnDeleteConfirm = () => {
    if (selectedUserId) {
      dispatch(deleteUsers(selectedUserId, paramsForUserTable));
    }
  };

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [email]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setUnverifiedCompany("");
    setCompany("");
    setEmail("");
    setRole("");
    setPassword("");
    setEmailVerificationCode("");
    setPasswordChangeCode("");
    setIsEmailVerified(false);
    setIsSurveyCompleted(false);
    setUsageType("");
    setPaymentType("");
    setRemainingUsages(0);
    setEndDate("");
  };

  const handleOnClose = () => {
    setSelectedUserId(undefined);
    setShowMainModal(false);
    setCompanyId(0);
    clearForm();
  };

  const handleOnSave = () => {
    const userPayload = {
      username: email,
      name: name,
      email: email,
      ...(password && { password }),
      role,
      unverifiedCompanyName: unverifiedCompany || undefined,
      ...(companyId !== 0 && {
        company: {
          name: company,
          id: companyId,
        }
      }),
      emailVerified: isEmailVerified,
      surveyCompleted: isSurveyCompleted,
      ...(emailVerificationCode && { emailVerificationCode }),
      ...(passwordChangeCode && { passwordChangeCode }),
      ...(isProUser && {
        planDetails: {
          planUsageType: usageType,
          planPaymentType: paymentType,
          planEndDate: endDate,
          planRemainingUsages: remainingUsages,
        }
      }),
    };

    if (selectedUserId) {
      dispatch(editUser(selectedUserId, userPayload, paramsForUserTable));
      clearForm();
    } else {
      dispatch(createUser(userPayload, paramsForUserTable));
      clearForm();
    }
  };

  const handleOnCloseDetailedUsageModal = () => {
    setShowDetailedUsageModal(false);
    setSelectedUsageId(undefined);
    dispatch(clearUsageDetailById());
  };

  const handleChangeVerifiedMail = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVerified(event.target.checked);
  };

  const handleChangeSurveyCompleted = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsSurveyCompleted(event.target.checked);
  };

  const paymentTypes = [
    { value: "MANUAL", label: "Manual" },
    {
      value: "CREDIT_CARD",
      label: ` 
  ${t("user.creditCard")}`,
    },
  ];

  const usageTypes = [
    {
      value: "END_TIME",
      label: ` 
  ${t("user.endTime")}`,
    },
    {
      value: "NUMBER_OF_USAGES",
      label: ` 
  ${t("user.usageNumber")}`,
    },
  ];

  const getDisableSaveButtonStatus = () => {
    const isBasicDataCompleted =
      !!name &&
      isValidEmail &&
      !!role &&
      (selectedUserId ? true : !!password);

    if (isFreeUser || isAdminUser) {
      return !(isBasicDataCompleted && !!unverifiedCompany);
    } else if (isProUser) {
      return !(
        isBasicDataCompleted &&
        !!unverifiedCompany &&
        !!usageType &&
        !!paymentType &&
        ((isNumberUsage && !!remainingUsages) || (isEndDateUsage && !!endDate))
      );
    } else if (isCompanyUser) {
      return !(isBasicDataCompleted && !!companyId);
    }
    return !isBasicDataCompleted;
  };

  useEffect(() => {
    setUnverifiedCompany("");
    setCompany(
      selectableCompanyList.find(({ value }) => value === companyId)?.label ||
      ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getRolesForInput = () => {
    if (companyId) {
      return rolesList?.filter(({ type }) => type === "GROUP");
    } else {
      return rolesList?.filter(({ type }) => type !== "GROUP");
    }
  };

  const getCreateUserModal = () => {
    return (
      <PungoModal
        open={showMainModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedUserId
            ? `${t("admin.editTitle")}`
            : `${t("admin.createTitle")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedUserId ? t("admin.editLabel") : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: getDisableSaveButtonStatus(),
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("user.nameLabel")}`}
            value={name}
            onChange={setName}
          />
          <PungoInput
            name={`${t("user.unverifiedCompanyLabel")}`}
            value={unverifiedCompany}
            onChange={setUnverifiedCompany}
            disabled={!!companyId}
          />
          <PungoInput
            name={`${t("user.companyLabel")}`}
            value={companyId}
            onChange={setCompanyId}
            type="select"
            options={selectableCompanyList}
          />
          <PungoInput
            name={`${t("user.emailLabel")}`}
            value={email}
            onChange={setEmail}
            disabled={!!selectedUserId}
            helperText={
              isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
            }
          />
          <PungoInput
            name={`${t("user.passwordLabel")}`}
            value={password}
            onChange={setPassword}
            type="password"
          />
          <PungoInput
            name={`${t("user.emailVerificationCodeLabel")}`}
            value={emailVerificationCode}
            onChange={setEmailVerificationCode}
          />
          <PungoInput
            name={`${t("user.passwordChangeCodeLabel")}`}
            value={passwordChangeCode}
            onChange={setPasswordChangeCode}
          />
          <PungoInput
            name={`${t("user.roleLabel")}`}
            value={role}
            onChange={setRole}
            type="select"
            options={getRolesForInput().map(({ name }) => {
              return { value: name, label: name };
            })}
          />

          {isProUser && !hasUserAssignedACompany && (
            <PungoInput
              name={`${t("user.paymentType")}`}
              value={paymentType || ""}
              onChange={setPaymentType}
              type="select"
              options={paymentTypes.map((type) => type)}
            />
          )}
          {isProUser && !hasUserAssignedACompany && (
            <PungoInput
              name={`${t("user.usageType")}`}
              value={usageType || ""}
              onChange={setUsageType}
              type="select"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && !hasUserAssignedACompany && isNumberUsage && (
            <PungoInput
              name={`${t("user.remainingUsages")}`}
              value={remainingUsages}
              onChange={setRemainingUsages}
              type="number"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && !hasUserAssignedACompany && isEndDateUsage && (
            <PungoInput
              name={`${t("user.usageEndDate")}`}
              value={endDate}
              type="date"
              onChange={(newValue) => setEndDate(newValue)}
            />
          )}
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEmailVerified}
                  onChange={handleChangeVerifiedMail}
                />
              }
              label={t("user.verifiedEmailLabel")}
              labelPlacement="start"
            />
          </div>
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSurveyCompleted}
                  onChange={handleChangeSurveyCompleted}
                />
              }
              label={t("user.completedSurveyLabel")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  const resetFilters = () => {
    setIdFilter(initialIdFilter);
    setStatusFilter(initialStatusFilter);
    setRoleFilter(initialRoleFilter);
    setNameFilter(initialNameFilter);
    setMailFilter(initialMailFilter);
    setCompanyFilter(initialUnverifiedCompanyFilter);
    setUnverifiedCompanyFilter(initialUnverifiedCompanyFilter);
    allFiltersChange.current = true;
  };

  const resetFiltersByUserId = () => {
    setUsageIdFilter(initialIdFilterByUserId);
    setUsageDateFilter(initialDateFilterByUserId);
    setUsageUserIdFilter(initialUserIdFilterByUserId);
    setUsageMailFilter(initialMailFilterByUserId);
    setUsageCallTypeFilter(initialCallTypeFilterByUserId);
    allUsageFiltersChange.current = true;
  };

  const showResetFilters =
    idFilter !== initialIdFilter ||
    statusFilter !== initialStatusFilter ||
    roleFilter !== initialRoleFilter ||
    nameFilter !== initialNameFilter ||
    mailFilter !== initialMailFilter ||
    companyFilter !== initialCompanyFilter ||
    unverifiedCompanyFilter !== initialUnverifiedCompanyFilter;

  const showResetFiltersUserById =
    usageDateFilter !== initialDateFilterByUserId ||
    usageIdFilter !== initialIdFilterByUserId ||
    usageUserIdFilter !== initialUserIdFilterByUserId ||
    usageMailFilter !== initialMailFilterByUserId ||
    usageCallTypeFilter !== initialCallTypeFilterByUserId;

  const handleOnCloseUsageModal = () => {
    dispatch(clearUsageDataByUser());
    setShowUsageModal(false);
    setSelectedUserId(undefined);
    resetFiltersByUserId();
  };

  useEffect(() => {
    dispatch(getRoles());
    dispatch(fetchAvailableCompanyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdminTable
        columns={columnsForUserTable}
        data={usersData || []}
        handleOnEdit={setShowMainModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedUserId}
        handleOnUsage={setShowUsageModal}
        actions={["stats", "add", "delete", "edit"]}
        shouldShowResetFilters={showResetFilters}
        handleOnResetFilters={resetFilters}
        pagination={{
          totalPages: totalUserPages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
      />
      {getCreateUserModal()}
      <PungoModal
        open={showUsageModal}
        title={`${t("admin.usageModal")}`}
        handleClose={handleOnCloseUsageModal}
        classNames={styles.modalParentForUsage}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseUsageModal,
        }}
      >
        <AdminTable
          columns={columnsForUsageTable}
          data={usageByUserData || []}
          handleOnUsage={setShowDetailedUsageModal}
          handleOnSelection={setSelectedUsageId}
          handleOnResetFilters={resetFiltersByUserId}
          shouldShowResetFilters={showResetFiltersUserById}
          actions={["stats"]}
          classNames={styles.usageModal}
          sorting={{
            sortedBy: usageSortBy,
            onSortedBy: setUsageSortBy,
            sortedType: usageSortType,
            onSortedType: setUsageSortType,
          }}
          pagination={{
            totalPages: totalUsageUserPages,
            currentPage: currentUsageTablePage,
            onCurrentPage: setCurrentUsageTablePage,
            pageSize: usagePageSize,
            onPageSize: setUsagePageSize,
          }}
        />
      </PungoModal>
      <PungoModal
        open={showDetailedUsageModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseDetailedUsageModal}
        classNames={styles.detailsModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseDetailedUsageModal,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(detailedData, null, 4)}</pre>
        </code>
      </PungoModal>
    </div>
  );
};

export default AdminUsersTable;
