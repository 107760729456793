import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import { store } from "../../store";
import {
  IWellInputData,
  saveProPlanErrors,
  saveProPlanResults,
  saveReportReference,
  saveResults,
  setFetchedMockedDataStatus,
} from "store/slices/plan";
import { v4 as uuidv4 } from "uuid";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import moment, { Moment } from "moment";
import { t } from "i18next";

import { clerUserSession, refresh } from "./authActions";
import { displaySnackbarAPISuccess, getProPlanErrorMessage, getSnackbarError, } from "./utils";
import { setSubscriptionPremiumPlan, setSubscriptionProPlan, setSubscriptionStandarPlan, } from "store/slices/auth";
import { saveProPlanErrorResponse } from "store/slices/planModal";

const calculateURL = getBaseUrlForAPI("calculate");
const calculationFile = getBaseUrlForAPI("calculationFile");
const subscribeURL = getBaseUrlForAPI("subscribePlan");
const mockedUpData = getBaseUrlForAPI("mockedDataForProPlan");
const subscribeRequestMessage = t("successMessage.subscribeMessage");
const resultsReport = getBaseUrlForAPI("resultsReport");

export const subscribeSimple =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `subscribeSimple-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${subscribeURL}subscribe-simple`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
            dispatch(setSubscriptionStandarPlan(true));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const subscribePro =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `subscribePro-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${subscribeURL}subscribe-pro`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
            dispatch(setSubscriptionProPlan(true));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const subscribePremium =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `subscribePremium-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${subscribeURL}subscribe-premium`)
          .then(() => {
            dispatch(displaySnackbarAPISuccess(subscribeRequestMessage));
            dispatch(setSubscriptionPremiumPlan(true));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const downloadCalculationTemplate =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `downloadCalculationTemplate-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${calculationFile}`, { responseType: "blob" })
          .then((response) => {
            let filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(".")[0];

            let url = URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${filename.substring(1)}.xlsx`;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch(async (error) => {
            if (typeof error?.response?.data === "object") {
              let parsedError = JSON.parse(await error?.response?.data.text());
              dispatch(
                saveProPlanErrorResponse({
                  text: getProPlanErrorMessage(
                    parsedError?.errors[0]?.arguments[0]
                  ),
                })
              );
            } else {
              getSnackbarError(error);
            }
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const downloadResultsReport =
  (reference: number) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `downloadResultsReport-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${resultsReport}/${reference}/xlsx`, { responseType: "blob" })
          .then((response) => {
            let filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(".")[0];

            let url = URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${filename.substring(1)}.xlsx`;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch(async (error) => {
            if (typeof error?.response?.data === "object") {
              let parsedError = JSON.parse(await error?.response?.data.text());
              dispatch(
                saveProPlanErrorResponse({
                  text: getProPlanErrorMessage(
                    parsedError?.errors[0]?.arguments[0]
                  ),
                })
              );
            } else {
              getSnackbarError(error);
            }
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const uploadCalculationTemplate =
  (uploadedFile: FormData) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `uploadCalculationTemplate-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(calculationFile, uploadedFile)
          .then(({ data }) => {
            dispatch(saveProPlanResults(data.wellResults));
            dispatch(saveReportReference(data.reference));
            dispatch(saveProPlanErrors(data.errors));
            dispatch(setFetchedMockedDataStatus(true));
          })
          .catch((error) => {
            dispatch(
              saveProPlanErrorResponse({
                text: getProPlanErrorMessage(
                  error.response.data.errors[0]?.arguments[0]
                ),
              })
            );
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const uploadCalculationTemplateWithModel =
  (uploadedFile: FormData, params: any) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `uploadCalculationTemplateWithModel-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${calculationFile}-with-model`, uploadedFile, { params })
          .then(({ data }) => {
            dispatch(saveProPlanResults(data.wellResults));
            dispatch(saveReportReference(data.reference));
            dispatch(setFetchedMockedDataStatus(true));
          })
          .catch((error) => {
            dispatch(
              saveProPlanErrorResponse({
                text: getProPlanErrorMessage(
                  error.response.data.errors[0]?.arguments[0]
                ),
              })
            );
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchMockedData =
  () =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchMockedData-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .get(`${mockedUpData}`)
          .then(({ data }) => {
            dispatch(saveProPlanResults(data.wellResults));
            dispatch(setFetchedMockedDataStatus(true));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };

export const fetchSimplePlanResults =
  (wellData: IWellInputData) =>
    (dispatch: AppDispatch): void => {
      const localRequest = () => {
        const id = `fetchSimplePlanResults-${uuidv4()}`;
        dispatch(addAPIcall(id));
        axiosInstance
          .post(`${calculateURL}`, wellData)
          .then(({ data }) => {
            dispatch(saveResults(data));
          })
          .catch((error) => {
            getSnackbarError(error);
          })
          .finally(() => {
            dispatch(completeAPIcall(id));
          });
      };

      const refreshToken = store.getState().authManagement.user?.refresh_token;
      let loginInfo = store.getState().authManagement.loginInfo;
      let actualTime: Moment = moment();

      if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
        // session ended
        dispatch(clerUserSession());
      } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
        // token needs refresh
        if (refreshToken) {
          dispatch(refresh({ refresh_token: refreshToken }, localRequest));
        } else {
          dispatch(clerUserSession());
        }
      } else {
        localRequest();
      }
    };
