import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  FirstPage,
  KeyboardArrowLeft,
  LastPage,
  KeyboardArrowRight,
  Insights,
  Download,
} from "@mui/icons-material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Add,
  Delete,
  Edit,
  BarChart,
  ContactPage,
  CancelRounded,
} from "@mui/icons-material";

import PungoClickable from "pungo-ui/PungoClickable";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import PungoButton from "pungo-ui/PungoButton";

import classnames from "classnames";
import styles from "./index.module.scss";

export interface ITableFilter {
  name: string;
  type: "text" | "list" | "date";
  value: string;
  auxValue?: string;
  setter: (value: string) => void;
  auxSetter?: (value: string) => void;
  eraser?: () => void;
  auxEraser?: () => void;
  options?: { label: string; value: string }[];
}

export interface ITableColumn {
  id?: string;
  field: string;
  headerName: string;
  minWidth: number;
  sortable: boolean;
  filter?: ITableFilter;
  valueGetter?: (params: any) => void;
}

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onCurrentPage: (size: number) => void;
  pageSize: number;
  onPageSize: (size: number) => void;
}

export interface SortingProps {
  sortedBy: string;
  onSortedBy: (id: string) => void;
  sortedType: string;
  onSortedType: (id: string) => void;
}

interface AdminTableProps {
  columns: ITableColumn[];
  data: any[];
  handleOnEdit?: (shouldShow: boolean) => void;
  handleOnUsage?: (shouldShow: boolean) => void;
  handleOnUser?: (shouldShow: boolean) => void;
  handleOnFetchRol?: () => void;
  handleOnDelete?: () => void;
  handleOnDownload?: (row: any) => void;
  handleOnSelection?: (id: number | undefined) => void;
  actions?: (
    | "stats"
    | "add"
    | "delete"
    | "edit"
    | "user"
    | "model"
    | "download"
  )[];
  classNames?: string;
  pagination?: PaginationProps;
  sorting?: SortingProps;
  shouldShowResetFilters?: boolean;
  handleOnResetFilters?: () => void;
  selectedRows?: number[];
}

const AdminTable: React.FC<AdminTableProps> = (props) => {
  const {
    columns,
    data,
    handleOnEdit,
    handleOnSelection,
    handleOnDelete,
    handleOnUser,
    handleOnUsage,
    handleOnFetchRol,
    handleOnDownload,
    classNames,
    actions,
    pagination,
    sorting,
    shouldShowResetFilters = false,
    handleOnResetFilters,
    selectedRows,
  } = props;
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getFilterField = (filter: ITableFilter): React.ReactElement | null => {
    switch (filter.type) {
      case "text":
        return (
          <PungoInput
            name={filter?.name}
            value={filter?.value}
            onChange={filter?.setter}
            onErase={filter?.eraser}
            size="small"
            canBeErased
          />
        );
      case "list":
        return (
          <PungoInput
            name={filter?.name}
            value={filter?.value}
            onChange={filter?.setter}
            type="select"
            options={filter?.options || []}
            size="small"
          />
        );
      case "date":
        return (
          <div className={styles.rangeDate}>
            <PungoInput
              name={t("admin.filtering.fromDate")}
              value={filter?.value}
              type="date"
              size="small"
              onChange={(newValue) => filter?.setter(newValue)}
              onErase={filter?.eraser}
              canBeErased
            />
            <PungoInput
              name={t("admin.filtering.toDate")}
              value={filter?.auxValue || ""}
              type="date"
              size="small"
              onChange={(newValue) => {
                if (filter?.auxSetter) {
                  filter.auxSetter(newValue);
                }
              }}
              onErase={filter?.auxEraser}
              canBeErased
            />
          </div>
        );
      default:
        return null;
    }
  };

  const getSortingComponent = (index: number) => {
    if (sorting) {
      if (sorting?.sortedType === "asc") {
        return (
          <div className={styles.arrowContainer}>
            <PungoButton
              type="icon"
              icon={
                <ArrowDownwardIcon
                  className={classnames(styles.arrow, {
                    [styles.selectedColumnArrow]:
                      columns[index]?.id === sorting.sortedBy,
                  })}
                />
              }
              onClick={() => {
                if (sorting?.sortedBy === columns[index]?.id) {
                  sorting.onSortedType("desc");
                  sorting.onSortedBy(columns[index]?.id || "");
                } else {
                  sorting.onSortedBy(columns[index]?.id || "");
                }
              }}
            />
          </div>
        );
      } else {
        return (
          <div className={styles.arrowContainer}>
            <PungoButton
              type="icon"
              icon={
                <ArrowUpwardIcon
                  className={classnames(styles.arrow, {
                    [styles.selectedColumnArrow]:
                      columns[index]?.id === sorting.sortedBy,
                  })}
                />
              }
              onClick={() => {
                if (sorting?.sortedBy === columns[index]?.id) {
                  sorting.onSortedType("asc");
                  sorting.onSortedBy(columns[index]?.id || "");
                } else {
                  sorting.onSortedBy(columns[index]?.id || "");
                }
              }}
            />
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const newColumns: any[] = [
    {
      field: "actions",
      headerName: `${t("admin.filtering.actions")}`,
      fixed: true,
      minWidth: 80,
      sortable: false,
      renderCell: ({ row }: any) => {
        const areActionsDisabled = row?.status === "REMOVED" || false;
        return (
          <div className={styles.actions}>
            {actions?.includes("stats") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnUsage) {
                    handleOnUsage(true);
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled,
                })}
              >
                <BarChart />
              </PungoClickable>
            )}
            {actions?.includes("model") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnUsage) {
                    handleOnUsage(true);
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled,
                })}
              >
                <Insights />
              </PungoClickable>
            )}
            {actions?.includes("edit") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnEdit) {
                    handleOnEdit(true);
                    if (handleOnFetchRol) {
                      handleOnFetchRol();
                    }
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled,
                })}
              >
                <Edit />
              </PungoClickable>
            )}
            {actions?.includes("delete") && (
              <PungoClickable
                onClick={() => setShowDeleteModal(true)}
                classNames={classnames(styles.delete, {
                  [styles.disabled]: areActionsDisabled,
                })}
              >
                <Delete />
              </PungoClickable>
            )}
            {actions?.includes("user") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnUser) {
                    handleOnUser(true);
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled,
                })}
              >
                <ContactPage />
              </PungoClickable>
            )}
            {actions?.includes("download") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnDownload) {
                    handleOnDownload(row);
                  }
                }}
                classNames={classnames(styles.edit)}
              >
                <Download />
              </PungoClickable>
            )}
          </div>
        );
      },
    },
    ...columns.map((column, index) => {
      if (column.filter) {
        return {
          headerClassName: styles.headerStyles,
          renderHeader: () => (
            <div className={styles.headerCellContainer}>
              {getFilterField(column.filter as ITableFilter)}
              {getSortingComponent(index)}
            </div>
          ),
          ...column,
        };
      } else {
        return { ...column };
      }
    }),
  ];

  const handleOnClose = () => {
    setShowDeleteModal(false);
    if (handleOnSelection) {
      handleOnSelection(undefined);
    }
  };

  const pageSizeOptions = [10, 15, 20];

  const CustomFooter = () => {
    if (pagination) {
      const { pageSize, onPageSize, currentPage, onCurrentPage } = pagination;
      return (
        <GridFooterContainer>
          <div className={styles.footerContainer}>
            <div className={styles.rowsPerPageContainer}>
              <PungoInput
                name={t("admin.rowsPerPageLabel")}
                value={pageSize}
                onChange={onPageSize}
                type="select"
                options={pageSizeOptions.map((size) => ({
                  value: size,
                  label: size,
                }))}
                size="small"
              />
            </div>
            <div className={styles.paginationContainer}>
              <PungoButton
                type="icon"
                icon={<FirstPage className={styles.footerIcon} />}
                onClick={() => onCurrentPage(0)}
                disabled={currentPage <= 0}
              />
              <PungoButton
                type="icon"
                icon={<KeyboardArrowLeft className={styles.footerIcon} />}
                onClick={() => onCurrentPage(currentPage - 1)}
                disabled={currentPage <= 0}
              />
              <div className={styles.currentPage}>{`${currentPage + 1}/${
                pagination.totalPages
              }`}</div>
              <PungoButton
                type="icon"
                icon={<KeyboardArrowRight className={styles.footerIcon} />}
                onClick={() => onCurrentPage(currentPage + 1)}
                disabled={currentPage >= pagination?.totalPages - 1}
              />
              <PungoButton
                type="icon"
                icon={<LastPage className={styles.footerIcon} />}
                onClick={() => onCurrentPage(pagination?.totalPages - 1)}
                disabled={currentPage >= pagination?.totalPages - 1}
              />
            </div>
          </div>
        </GridFooterContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classnames(classNames, styles.grid)}>
      {actions?.includes("add") || shouldShowResetFilters ? (
        <div className={styles.upperActions}>
          {shouldShowResetFilters ? (
            <PungoButton
              onClick={() => {
                if (handleOnResetFilters) {
                  handleOnResetFilters();
                }
              }}
              type="outlined"
              icon={<CancelRounded />}
              color="primary"
              iconPosition="right"
              label={`${t("admin.filtering.resetFilters")}`}
            />
          ) : (
            <div />
          )}
          {actions?.includes("add") && (
            <PungoButton
              onClick={() => {
                if (handleOnEdit) {
                  handleOnEdit(true);
                  if (handleOnFetchRol) {
                    handleOnFetchRol();
                  }
                }
              }}
              icon={<Add className={styles.addIcon} />}
              type="icon"
            />
          )}
        </div>
      ) : undefined}

      <DataGrid
        rows={data}
        className={styles.table}
        columns={actions?.length ? newColumns : columns}
        getRowId={(row) => row.id}
        disableColumnMenu
        selectionModel={selectedRows}
        components={{ Footer: CustomFooter }}
        onSelectionModelChange={(row) => {
          if (handleOnSelection) {
            handleOnSelection(+row[0]);
          }
        }}
      />
      <PungoModal
        handleClose={handleOnClose}
        classNames={styles.deleteModal}
        open={showDeleteModal}
        primaryAction={{
          label: t("admin.deleteLabel"),
          onClick: handleOnDelete,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <Trans>{t("admin.deleteElementLabel")}</Trans>
      </PungoModal>
    </div>
  );
};

export default AdminTable;
