import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeLanguage } from "i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";

import TeamSection from "./sections/TeamSection";
import BenefitsSection from "./sections/BenefitsSection";
import WorkflowSection from "./sections/WorkflowSection";
import OpinionSection from "./sections/OpinionSection";
import PlanTableSection from "./sections/PlanTableSection";
import FAQSection from "./sections/FAQSection";
import ContactUsSection from "./sections/ContactUsSection";
import PartnersSection from "./sections/PartnersSection";
import AnimationSection from "./sections/AnimationSection";
import PungoButton from "../../pungo-ui/PungoButton";
import {
  selectSurveyStatus,
  selectUserName,
  selectUserRole,
} from "store/slices/auth";
import { getSurvey } from "store/actions/surveyActions";
import { saveSelectedPlan } from "store/slices/plan";

import styles from "./index.module.scss";

const AstroPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(selectUserName);
  const userRoles = useSelector(selectUserRole);
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const isSpanishLanguageSelected = i18n.language === "es";
  const isCompanyUser =
    userRoles.includes("company_admin") || userRoles.includes("company_user");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLogged && !isSurveyCompleted) {
      dispatch(getSurvey());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToDemo = () => {
    if (!isLogged) {
      navigate("signup");
    } else if (isCompanyUser) {
      navigate("/plan");
      dispatch(saveSelectedPlan("pro"));
    } else if (isSurveyCompleted) {
      navigate("/plan");
    } else {
      navigate("/survey");
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AnimationSection />
      <div className={styles.button}>
        <PungoButton
          onClick={handleGoToDemo}
          label={isLogged ? `${t("goToFreePlan")}` : `${t("demoLabel")}`}
          color="secondary"
          classNames={styles.demoButton}
        />
      </div>
      <BenefitsSection />
      <WorkflowSection />
      <OpinionSection />
      {!isCompanyUser && <PlanTableSection />}
      <FAQSection />
      <TeamSection />
      <PartnersSection />
      <ContactUsSection />
      <div className={styles.languageSection}>
        <PungoButton
          label={`${t("language.englishLabel")}`}
          type="text"
          onClick={() => changeLanguage("en")}
          color="secondary"
          disabled={!isSpanishLanguageSelected}
        />
        <PungoButton
          label={`${t("language.spanishLabel")}`}
          type="text"
          onClick={() => changeLanguage("es")}
          color="secondary"
          disabled={isSpanishLanguageSelected}
        />
      </div>
      <PungoButton
        label={`${t("politicsAndReferenceTerms")}`}
        type="text"
        onClick={() => navigate("/terms")}
        color="secondary"
      />
      <div className={styles.copyright}>Copyright © 2023 Pungo</div>
    </div>
  );
};

export default AstroPage;
