import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";
import SelectableTabs from "../../common/SelectableTabs";
import PungoDemoResultsCard from "../../../../../../pungo-ui/PungoDemoResultsCard/index";
import PungoInput from "pungo-ui/PungoInput";

import styles from "./index.module.scss";
import colors from "../../../../../../styles/export.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const Criticality: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProResults);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sortType, setSortType] = useState("not sorting");

  const labelsForConsolidatedResultsTable = [
    t("proPlan.tableLabels.criticality.well"),
    t("proPlan.tableLabels.criticality.date"),
    t("proPlan.tableLabels.criticality.bopdProduction"),
    t("proPlan.tableLabels.criticality.corrosionSpeedMax"),
    t("proPlan.tableLabels.criticality.saturationMax"),
    t("proPlan.tableLabels.criticality.totalCriticality"),
    t("proPlan.tableLabels.criticality.priority"),
  ];

  const sortingOptions = [
    {
      value: "not sorting",
      label: `
  ${t("DemoPage.ResultsSection.notSorting")}`,
    },
    {
      value: "highest to lowest",
      label: `
  ${t("DemoPage.ResultsSection.highestToLowest")}`,
    },
    {
      value: "lowest to highest",
      label: `
  ${t("DemoPage.ResultsSection.lowestToHighest")}`,
    },
  ];

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy alto" : "Very high";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const getStackedDataForCriticity = (sortType: string): any => {
    switch (sortType) {
      case "not sorting":
        return {
          labels: [...proPlanResults].map(({ wellName }) => wellName),
          datasets: [
            {
              data: [...proPlanResults].map(
                ({ wellCriticalityResultOutput }) =>
                  wellCriticalityResultOutput.totalCriticalityIndex
              ),
              backgroundColor: colors.green2,
            },
          ],
        };
      case "highest to lowest":
        return {
          labels: [...proPlanResults]
            .sort(
              (a, b) =>
                b.wellCriticalityResultOutput.totalCriticalityIndex -
                a.wellCriticalityResultOutput.totalCriticalityIndex
            )
            .map(({ wellName }) => wellName),
          datasets: [
            {
              data: [...proPlanResults]
                .sort(
                  (a, b) =>
                    b.wellCriticalityResultOutput.totalCriticalityIndex -
                    a.wellCriticalityResultOutput.totalCriticalityIndex
                )
                .map(
                  ({ wellCriticalityResultOutput }) =>
                    wellCriticalityResultOutput.totalCriticalityIndex
                ),
              backgroundColor: colors.green2,
            },
          ],
        };
      case "lowest to highest":
        return {
          labels: [...proPlanResults]
            .sort(
              (a, b) =>
                a.wellCriticalityResultOutput.totalCriticalityIndex -
                b.wellCriticalityResultOutput.totalCriticalityIndex
            )
            .map(({ wellName }) => wellName),
          datasets: [
            {
              data: [...proPlanResults]
                .sort(
                  (a, b) =>
                    a.wellCriticalityResultOutput.totalCriticalityIndex -
                    b.wellCriticalityResultOutput.totalCriticalityIndex
                )
                .map(
                  ({ wellCriticalityResultOutput }) =>
                    wellCriticalityResultOutput.totalCriticalityIndex
                ),
              backgroundColor: colors.green2,
            },
          ],
        };

      default:
        return {
          labels: [...proPlanResults].map(({ wellName }) => wellName),
          datasets: [
            {
              data: [...proPlanResults].map(
                ({ wellCriticalityResultOutput }) =>
                  wellCriticalityResultOutput.totalCriticalityIndex
              ),
              backgroundColor: colors.green2,
            },
          ],
        };
    }
  };

  const stackedDataForContribution = {
    labels: proPlanResults?.map(({ wellName }) => wellName) || [],
    datasets: [
      {
        label: t("proPlan.labels.production"),
        data: proPlanResults?.map(({ wellCriticalityResultOutput }) => {
          const {
            production_criticality,
            scale_criticality,
            corrosion_criticality,
          } = wellCriticalityResultOutput.criticalityValues;

          return (
            (production_criticality * 100) /
            (production_criticality + scale_criticality + corrosion_criticality)
          );
        }),
        backgroundColor: colors.green1,
      },
      {
        label: t("proPlan.labels.corrosion"),
        data: proPlanResults?.map(({ wellCriticalityResultOutput }) => {
          const {
            production_criticality,
            scale_criticality,
            corrosion_criticality,
          } = wellCriticalityResultOutput.criticalityValues;

          return (
            (corrosion_criticality * 100) /
            (production_criticality + scale_criticality + corrosion_criticality)
          );
        }),
        backgroundColor: colors.blue4,
      },
      {
        label: t("proPlan.labels.escale"),
        data: proPlanResults?.map(({ wellCriticalityResultOutput }) => {
          const {
            corrosion_criticality,
            production_criticality,
            scale_criticality,
          } = wellCriticalityResultOutput.criticalityValues;

          return (
            (scale_criticality * 100) /
            (scale_criticality + production_criticality + corrosion_criticality)
          );
        }),
        backgroundColor: colors.red1,
      },
    ],
  };

  const barChartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          color: colors.gray1,
          text: `${t("proPlan.axisLabels.wells")}`,
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.blue4}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          color: colors.gray1,
          text: `${t("proPlan.axisLabels.totalCriticality")}`,
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.blue4}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
    },
  };

  const barChartConfigForContribution = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          color: colors.gray1,
          text: `${t("proPlan.axisLabels.wells")}`,
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.blue4}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          color: colors.gray1,
          text: `${t("proPlan.axisLabels.totalCriticality")}`,
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.blue4}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
    },
  };

  const getTableContent = () => (
    <div className={styles.container}>
      <div className={styles.table}>
        <PungoTable
          values={proPlanResults?.map(
            ({
              wellName,
              corrosionResult,
              saturationIndexResult,
              wellCriticalityResultOutput,
              date,
            }) => {
              return [
                wellName,
                date,
                wellCriticalityResultOutput.bopd,
                corrosionResult.corrosionMax,
                saturationIndexResult.saturationIndexMax,
                wellCriticalityResultOutput.totalCriticalityIndex,
                translateResults(wellCriticalityResultOutput.treatmentPriority),
              ];
            }
          )}
          labels={labelsForConsolidatedResultsTable}
          height={300}
        />
      </div>
      <div className={styles.tabs}>
        <SelectableTabs
          labels={proPlanResults?.map(({ wellName }) => wellName)}
          onSelect={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.wellProduction") as string}
            result={`${proPlanResults[selectedIndex].wellCriticalityResultOutput.bopd} BOPD`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.wellCriticalityRate") as string
            }
            result={
              proPlanResults[selectedIndex].wellCriticalityResultOutput
                .totalCriticalityIndex
            }
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.chemicalTreatment") as string
            }
            result={translateResults(
              proPlanResults[selectedIndex].wellCriticalityResultOutput
                .treatmentPriority
            )}
            color={getColorForResults(
              proPlanResults[selectedIndex].wellCriticalityResultOutput
                .treatmentPriority
            )}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.wellCriticalityRateTitle")}
        </div>
        <div className={styles.sortSelector}>
          <PungoInput
            name={t("DemoPage.ResultsSection.sortingTypeLabel")}
            value={sortType}
            onChange={setSortType}
            type="select"
            options={sortingOptions}
          />
        </div>
        <div className={styles.barChartContainer}>
          <div className={styles.barChart}>
            <Bar
              data={getStackedDataForCriticity(sortType)}
              options={barChartConfig}
            />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("proPlan.labels.criticalityContribution")}
        </div>
        <div className={styles.barChartContainer}>
          <div className={styles.barChart}>
            <Bar
              data={stackedDataForContribution}
              options={barChartConfigForContribution}
            />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.section}>
          <div className={styles.message}>
            <Trans>{t("DemoPage.ResultsSection.criticalityMessage")}</Trans>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PungoDemoAccordion
      title={t("DemoPage.ResultsSection.wellCriticality")}
      content={getTableContent()}
    />
  );
};

export default Criticality;
