import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

import { selectSurveyStatus, selectUserRole } from "store/slices/auth";
import AnimationSection from "./sections/AnimationSection";
import DataInputSection from "./sections/DataInputSection";
import InstructionsSection from "./sections/InstructionsSection";
import ResultsSection from "./sections/ResultsSection";
import PlanTableSection from "components/AstroPage/sections/PlanTableSection";
import FAQSection from "components/AstroPage/sections/FAQSection";
import ContactUsSection from "components/AstroPage/sections/ContactUsSection";
import PungoButton from "pungo-ui/PungoButton";
import PlanSelectorSection from "./sections/DemoPlanSelectorSection";
import {
  selectFetchedProResultsStatus,
  selectSelectedPlan,
} from "store/slices/plan";
import PlanInformationSection from "./sections/PlanInformationSection";
import DataInputProSection from "./sections/DataInputProSection";
import ResultsProSection from "./sections/ResultsProSection";
import { fetchMockedData } from "store/actions/planActions";
import { useAppDispatch } from "app/hooks";

import styles from "./index.module.scss";

const DemoPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const selectedPlan = useSelector(selectSelectedPlan);
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");

  const isSpanishLanguageSelected = i18n.language === "es";
  const isCompanyUser =
    userRoles.includes("company_admin") || userRoles.includes("company_user");

  useEffect(() => {
    if (isCompanyUser) {
      navigate("/plan");
    } else if (!isSurveyCompleted) {
      navigate("/survey");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyCompleted]);

  useEffect(() => {
    if (isFreeUser) {
      dispatch(fetchMockedData());
    }
  }, []);

  return (
    <section className={styles.container}>
      <AnimationSection />
      {!isCompanyUser && <PlanSelectorSection />}
      <PlanInformationSection />
      <InstructionsSection />
      {selectedPlan === "free" ? (
        <DataInputSection />
      ) : areProResultsFetched ? (
        <DataInputProSection />
      ) : undefined}
      {selectedPlan === "free" ? (
        <ResultsSection />
      ) : areProResultsFetched ? (
        <ResultsProSection />
      ) : undefined}
      {!isCompanyUser && <PlanTableSection />}
      <FAQSection />
      <ContactUsSection />
      <div className={styles.languageSection}>
        <PungoButton
          label={`${t("language.englishLabel")}`}
          type="text"
          onClick={() => changeLanguage("en")}
          color="secondary"
          disabled={!isSpanishLanguageSelected}
        />
        <PungoButton
          label={`${t("language.spanishLabel")}`}
          type="text"
          onClick={() => changeLanguage("es")}
          color="secondary"
          disabled={isSpanishLanguageSelected}
        />
      </div>
      <div className={styles.copyright}>Copyright © 2023 Pungo</div>
    </section>
  );
};

export default DemoPage;
